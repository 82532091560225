<template>
  <v-container>
    <!-- 編集エリア -->
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row class="mt-2">
        <v-col>
          <h2>メールテンプレート編集</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2" sm="2" md="2" lg="2">
            <v-select
              label="種別"
              v-model="templateData.type"
              :items="mailTemplateTypeList"
              dense
              :readonly="mode == 'delete'"
              :rules="[requiredSelect]"
              :loading="loading"
            ></v-select>
        </v-col>
        <v-col cols="4" sm="4" md="4" lg="4">
          <v-text-field
            label="テンプレート名"
            v-model="templateData.templateName"
            dense
            :readonly="mode == 'delete'"
            :rules="[]"
            :loading="loading"
            :maxLength="50"
            :counter="50"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9" sm="9" md="9" lg="9">
          <v-text-field
            label="発信者"
            v-model="templateData.mailFrom"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="showEmailAddress(0, true, mailDestArray.tmpFromList)" :disabled="loading || (mode == 'delete')" ><v-icon :small="true">mdi-border-color</v-icon>発信者変更</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9" sm="9" md="9" lg="9">
          <v-text-field
            label="宛先"
            v-model="templateData.mailTo"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="showEmailAddress(1, false, mailDestArray.tmpToList)" :disabled="loading || (mode == 'delete')" ><v-icon :small="true">mdi-border-color</v-icon>宛先変更</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9" sm="9" md="9" lg="9">
          <v-text-field
            label="CC"
            v-model="templateData.mailCC"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="showEmailAddress(2, false, mailDestArray.tmpCCList)" :disabled="loading || (mode == 'delete')" ><v-icon :small="true">mdi-border-color</v-icon>CC変更</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9" sm="9" md="9" lg="9">
          <v-text-field
            label="BCC"
            v-model="templateData.mailBCC"
            dense
            :readonly="true"
            :clearable="false"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="showEmailAddress(3, false, mailDestArray.tmpBCCList)" :disabled="loading || (mode == 'delete')" ><v-icon :small="true">mdi-border-color</v-icon>BCC変更</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-text-field
            label="件名"
            v-model="templateData.subject"
            dense
            :readonly="mode == 'delete'"
            :clearable="false"
            :rules="[]"
            :loading="loading"
            :maxLength="100"
            counter="100"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-textarea
            label="本文"
            v-model="templateData.body"
            dense
            :readonly="mode == 'delete'"
            :clearable="false"
            :rules="[]"
            :loading="loading"
            :maxLength="30000"
            counter="30000"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-textarea
            label="署名"
            v-model="templateData.signature"
            dense
            :readonly="mode == 'delete'"
            :clearable="false"
            :rules="[]"
            :loading="loading"
            :maxLength="30000"
            counter="30000"
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- アクション -->
      <v-row>
        <v-col cols="6" sm="6" md="6" lg="6" class="text-center">
          <v-btn v-if="mode != 'delete'" class="btn_font primary" width="120" v-on:click="registData()" :disabled="loading">保存</v-btn>
          <v-btn v-else class="btn_font secondary" width="120" v-on:click="deleteData()" :disabled="loading">削除</v-btn>
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="6" class="text-center">
          <v-btn class="btn_font primary" width="120" v-on:click="cancel()" :disabled="loading" >キャンセル</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <!-- modal -->
    <modal name="modal-dialog-from" :draggable="false" :clickToClose="false" :scrollable="true" 
                                height="auto" width="80%" id="vm--modal-from">
      <div class="">
        <div class="d-flex modal-header mt-5 justify-space-between">
          <h2>メールアドレス選択</h2>
          <v-icon x-large v-on:click="hideFrom()" :disabled="loading">mdi-close-circle</v-icon>
        </div>
        <div class="modal-body">
          <SelectEmailAddress v-on:closeEvt="hideFrom" v-on:registEvt="updateEmailAddressList" :selectedList="modalSelectedList" :type="emailType" :solo="emailSolo" />
        </div>
      </div>
    </modal>
  </v-container>
</template>

<script>
import Debug from '../Lib/Debug'
import Mixin from '../mixins/const';
import Message from "../Lib/Message";

import SelectEmailAddress from './SelectEmailAddress.vue';

import MailTemplate from "../model/mailTemplate";

export default {
  mixins: [Mixin],

  components: {
    SelectEmailAddress,
  },

  data: () => ({
    valid : true,
    loading: false,

    show: true,

    mode: '',
    editId: '',

    // メールアドレス選択
    emailType: 0,
    emailSolo: false,

    // テンプレートデータ
    templateData: {
      templateId: null,
      templateName: null,
      type: 0,
      mailFrom: null,
      mailTo: null,
      mailCC: null,
      mailBCC: null,
      subject: null,
      body: null,
      signature: null,
      use: 1,
    },
    // メールアドレス一時リスト
    mailDestArray: {
      tmpFromList: [],
      tmpToList: [],
      tmpCCList: [],
      tmpBCCList: [],
    },

    // モーダル用リスト
    modalSelectedList: [],

  }),

  // ライフサイクルフック
  created: function () {
    this.mode = this.$store.getters.getMode; // 新規 or 編集 or 削除をstoreから取得
    // 新規以外の場合
    if (this.mode != "new") {
      this.editId = this.$store.getters.getEditId; // 編集 or 削除するテンプレートIDをstoreから取得
    }

    this.loadData();
  },

  mounted: async function () {

  },

  methods: {
    loadData() {
      Debug.log('function[loadData]');

      if (this.mode == "new") return;

      this.loading = true;

      MailTemplate.getData(this.editId)
        .then(res => {
          if (res && res.data) {
            this.templateData = res.data.template;
            this.mailDestArray.tmpFromList = res.data.from;
            this.mailDestArray.tmpToList = res.data.to;
            this.mailDestArray.tmpCCList = res.data.cc;
            this.mailDestArray.tmpBCCList = res.data.bcc;

            Debug.log2('templateData:',this.templateData);
            Debug.log2('mailDestArray:',this.mailDestArray);
          }
        })
        .catch((err) => {
          Message.err(err, '情報を取得できませんでした。[メールテンプレートデータ]');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // キャンセル
    cancel() {
      if (!confirm("変更をキャンセルします。よろしいですか？")) return;
      this.$router.back();
    },

    // 保存
    async registData() {
      Debug.log('function[registData]');
      
      if (!this.$refs.form.validate()) {
        alert("入力に誤りがあります。内容をご確認ください。");
        return;
      }

      if (!confirm("更新します。よろしいですか？")) return;

      try {
        const res = await MailTemplate.updateData(this.templateData, this.mailDestArray, this.mode);

        // サーバーサイドのバリデーション
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";

          if (Array.isArray(validationMsg)) {
            validationMsg.forEach((m) => (message += m + "\n"));
          } else {
            message = validationMsg;
          }

          alert(message);
          this.loading = false;
          return;
        } else {
          alert("更新しました。");
          this.$router.back();
        }
      } catch (error) {
        Message.err(error, "更新できませんでした");
      }
    },

    /** 削除 */
    async deleteData() {
      Debug.log("function[deleteData]");

      if (!confirm("削除します。よろしいですか？")) return;
      this.loading = true;
      MailTemplate.deleteData(this.templateData.templateId)
        .then(res => {
          Debug.log(res);
          alert("削除しました");
          this.$router.back();
        })
        .catch((err) => {
          alert('削除できませんでした。[deleteData]')
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // メールアドレス更新
    updateEmailAddressList (type, innerSelectedList, tmpStr) {
      Debug.log('function[updateEvt]');
      this.loading = true;

      // 編集画面での変更を反映する
      switch(type) {
        case 0:
          // 発信者
          this.templateData.mailFrom = tmpStr;
          if(Object.keys(innerSelectedList).length > 0) {
            // 設定あり
            this.mailDestArray.tmpFromList = [innerSelectedList];
          } else {
            // 設定なし
            this.mailDestArray.tmpFromList = [];
          }
          
          Debug.log2('tmpFromList:',this.mailDestArray.tmpFromList);
          break;

        case 1:
          // 宛先
          this.templateData.mailTo = tmpStr;
          this.mailDestArray.tmpToList = innerSelectedList;
          break;

        case 2:
          // CC
          this.templateData.mailCC = tmpStr;
          this.mailDestArray.tmpCCList = innerSelectedList;
          break;

        case 3:
          // BCC
          this.templateData.mailBCC = tmpStr;
          this.mailDestArray.tmpBCCList = innerSelectedList;
          break;
      }

      this.loading = false;
    },

    /** アドレス帳モーダル表示用 */
    showEmailAddress (type, solo, selectedList) {
      Debug.log('function[show]');

      this.emailType = type;
      this.emailSolo = solo;
      this.modalSelectedList = selectedList;

      Debug.log2('modalSelectedList:', this.modalSelectedList);
      this.$modal.show('modal-dialog-from');
    },
    hideFrom : function () {
      Debug.log('function[hide]');
      this.$modal.hide('modal-dialog-from');
    },

  },

  computed: {
  },

  watch: {
  },
};
</script>

<style scoped>

.disablecolor {
  background: #bbbbbb;
}



</style>
